import '../css/landing_page.css';

// This is to make sure that all images are included in the build
import.meta.glob([
    '../assets/images/**',
    '../assets/logos/**',
]);

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import translations from './translations.json';
import PricesWidget from '@/Pages/Settings/Partials/PricesWidget.vue';

const i18n = createI18n({
    legacy: false,
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: translations,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

const pricesWidgetElement = document.getElementById('prices-widget');

createApp(PricesWidget, pricesWidgetElement.widgetData)
    .use(i18n)
    .mount(pricesWidgetElement);
